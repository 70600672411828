import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import { colors, space, palette, fontSizes, mediaQueries } from '../../../utils/tokens'

const Card = ({ name, onSelect, isSelected }) => {
  return (
    <button
      onClick={onSelect}
      css={css({
        borderRadius: `4px`,
        cursor: `pointer`,
        padding: `${space[4]}px`,
        transition: `background-color 300ms, border 300ms`,
        '&:hover, &:focus': {
          backgroundColor: palette.orange[10],
          color: palette.orange[90],
          borderColor: colors.primaryBrand,
        },
        backgroundColor: isSelected ? palette.orange[20] : colors.white,
        border: isSelected
          ? `2px solid ${colors.primaryBrand}`
          : `2px solid ${palette.grey[40]}`,
        color: isSelected ? palette.orange[90] : `initial`,
      })}
    >
      <span
        css={css({
          fontWeight: `bold`,
        })}
      >
        {name}
      </span>
    </button>
  )
}

const CardWithImage = ({ name, onSelect, isSelected, image, isRecommended }) => {
  return (
    <div
      aria-label="button"
      onClick={onSelect}
      css={css({
        borderRadius: `4px`,
        cursor: `pointer`,
        transition: `background-color 300ms, border 300ms`,
        padding: 0,
        display: `flex`,
        flexDirection: `column`,
        boxSizing: `border-box`,
        '&:hover, &:focus': {
          backgroundColor: palette.orange[10],
          color: palette.orange[90],
          borderColor: colors.primaryBrand,
        },
        backgroundColor: isSelected ? palette.orange[20] : colors.white,
        border: isSelected ? `2px solid ${colors.primaryBrand}` : `2px solid ${palette.grey[40]}`,
        color: isSelected ? palette.orange[90] : `initial`,
        position: `relative`,
        overflow: `hidden`,
      })}
    >
      {isRecommended && (
        <div
          css={css({
            position: `absolute`,
            top: `${space[3]}px`,
            left: `${space[3]}px`,
            zIndex: 1,
            backgroundColor: palette.green[20],
            fontSize: fontSizes[1],
            fontWeight: `500`,
            display: `flex`,
            justifyContent: `center`,
            textAlign: `center`,
            color: palette.green[90],
            borderRadius: `4px`,
            padding: `${space[1]}px ${space[2]}px`,
          })}
        >
          <span>recommended</span>
        </div>
      )}
      {image && image.fluid && (
        <div
          css={css({
            width: `100%`,
            position: `relative`,
            overflow: `hidden`,
            padding: `${space[2]}px`,
          })}
        >
          <Img fluid={image.fluid} alt={image.title} />
        </div>
      )}
      <div
        css={css({
          textAlign: `center`,
          padding: `0 ${space[2]}px ${space[2]}px`,
          justifySelf: `center`,
          alignSelf: `center`,
        })}
      >
        <span
          css={css({
            fontWeight: `bold`,
          })}
        >
          {name}
        </span>
      </div>
    </div>
  )
}

const OtherCard = ({
  name,
  onSelect,
  isSelected,
  image,
  isRecommended,
}) => {
  return (
    <div
      aria-label="button"
      onClick={onSelect}
      css={css({
        borderRadius: `4px`,
        cursor: `pointer`,
        transition: `background-color 300ms, border 300ms`,
        padding: 0,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        minHeight: `150px`,
        boxSizing: `border-box`,
        '&:hover, &:focus': {
          backgroundColor: palette.orange[10],
          color: palette.orange[90],
          borderColor: colors.primaryBrand[50],
        },
        backgroundColor: isSelected ? palette.orange[20] : colors.white,
        border: isSelected
          ? `2px solid ${colors.primaryBrand}`
          : `2px solid ${palette.grey[40]}`,
        color: isSelected ? palette.orange[90] : `initial`,
        position: `relative`,
        overflow: `hidden`,
      })}
    >
      <div
        css={css({
          textAlign: `center`,
          padding: `0 ${space[2]}px ${space[2]}px`,
          justifySelf: `center`,
          alignSelf: `center`,
        })}
      >
        <span
          css={css({
            fontWeight: `bold`,
            fontSize: fontSizes[3],
          })}
        >
          {name}
        </span>
      </div>
    </div>
  )
}

const FormCardSelection = ({
  items,
  onSelect,
  selectedValue,
  hasImage,
  hasOther,
}) => {

  const findSelectedIndex = () => {
    return items.findIndex(val => val.label === selectedValue)
  }

  const handleSelect = (id) => {
    onSelect && onSelect(items[id].label)
  }

  return (
    <div
      css={css({
        display: `grid`,
        gridTemplateColumns: `1fr 1fr`,
        gridGap: `${space[3]}px`,
        [mediaQueries.lg]: {
          gridTemplateColumns: `repeat(auto-fit, minmax(175px, 1fr))`,
        },
      })}
    >
      {items &&
        items.map((item, id) => {
          if (hasImage) {
            return (
              <CardWithImage
                key={id}
                name={item.label}
                image={item.image}
                onSelect={() => handleSelect(id)}
                isSelected={id === findSelectedIndex()}
                isRecommended={item.isRecommended}
              />
            )
          }

          return (
            <Card
              key={id}
              name={item.label}
              onSelect={() => handleSelect(id)}
              isSelected={id === findSelectedIndex()}
            />
          )
        })}
      {hasOther && (
        <OtherCard
          name="Other"
          onSelect={() => onSelect('Other')}
          isSelected={selectedValue === 'Other'}
        />
      )}
    </div>
  )
}

FormCardSelection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  onSelect: PropTypes.func,
}

export default FormCardSelection