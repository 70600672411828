import React, { useContext } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormCardSelection from '../../../views/custom-quote/components/FormCardSelection'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'

const Materials = () => {
  const { formData, updateFormData } = useContext(CustomQuoteContext)

  const data = useStaticQuery(graphql`
    query {
      allContentfulMaterial(
        filter: { isAvailableForCustomQuote: { eq: true } }
      ) {
        edges {
          node {
            name
            coverPhoto {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            isRecommended
          }
        }
      }
    }
  `)

  const materials = data.allContentfulMaterial.edges

  const handleSelect = (item) => {
    updateFormData({
      ...formData,
      material: item,
    })
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.materials.next)
  }

  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.materials.next)
  }

  const items = materials.map(({ node }) => {
    return {
      id: node.id,
      label: node.name,
      image: node.coverPhoto,
      isRecommended: node.isRecommended,
    }
  })
  
  const sortedItemsByRecommendation = [...items].sort((x, y) => y.isRecommended - x.isRecommended)

  return (
    <CustomQuoteLayout>
      <FormQuestion
        title="What material do you need?"
        description="Our resin materials produce high resolution 3d prints, and smooth surface perfect for any type of projects."
      />
      <FormCardSelection
        items={sortedItemsByRecommendation}
        onSelect={handleSelect}
        selectedValue={formData && formData.material}
        hasImage
        hasOther
      />
      <FormNavigation
        onSubmit={handleSubmit}
        showBackButton
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.materials.previous}
      />
    </CustomQuoteLayout>
  )
}

export default Materials